.sub-top-bar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  /* align-items: flex-start; */
}


.sub-top-bar a {
  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  color: var(--color-primary-variant);
 }

 .sub-top-bar a:hover{
  /* font-size: 17px; */
  color: var(--color-mint);
  font-weight: 600;
 }

 .sub-top-bar .tab.selected {
   text-decoration: underline;
   text-underline-offset: 0.5rem;
   text-decoration-thickness: 0.2rem;
   font-weight: 600;
   color: var(--color-mint);

 }