.conferences-body {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--color-gray-light);
  padding: 1.5rem;
  gap: 2rem;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10); */
  border-radius: 8px;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  border: 0.5px var(--color-gray-light) solid;
}

.conferences-body:hover {
  background-color: var(--color-white);
  border: 0.5px var(--color-mint) solid;
}

.conference-info--detail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.conference-info--detail-description {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.conference-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* padding: 0 2rem; */
}

.conference-info h5 {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  word-wrap: break-word;
  max-width: 38rem;
}

.conference-info p {
  color: #747474;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.conference-info img {
  width: 1rem;
}
.session__card-editable-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-basis: 5%;
  justify-content: flex-end;
  align-items: flex-start;
}

.session__card-editable-buttons > button {
  background-color: transparent;
}

.rating {
  display: flex;

  font-size: 20px;
}

.star {
  color: #d9d9d9;
  cursor: pointer;
}

.star.active {
  color: #fff705;
}

.session__detail__card {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  gap: 1.5rem;
  padding: 1.5rem;
  width: 100%;
  margin-left: -1rem;
  min-height: 12rem;
  /* height: 15rem; */
  
}

.session-detail-card--header{
  flex-basis: 5%;
}

.session-details-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: wrap;
  overflow: hidden;
  
}

.session-details-card > h5{
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  word-wrap: break-word;
  max-width: 30rem;
  word-wrap: break-word;
  
}

.session-info--detail-card {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.session-info--detail-card > p{
  color: #747474;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.session-info--detail-card > img {
  width: 0.8rem;
}

.session-info--detail-description > h5{
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  width: 100%;
  padding-right: 2rem;

}

.sessioncardInterval {
  background-color: #fff705;
}
