/* Style the main chat container */
.SessionChat {
    border: 1px solid #ccc;
    padding: 1.5rem;
    margin: 16px;
    background-color: #EDECEC;
    border-radius: 10px;
    min-height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 3px #D9D9D9;
    
  }
  
  /* Style the question card */
  .CQuestionCard {
    margin: 12px;
    /* padding-top: 12px; */
    /* padding: 16px; */
    /* margin-left: 50px; */
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    width: auto;
    display: flex;
    flex-direction: column;
    margin-right: 1.5rem
  }


  .CQuestionCard-content {
    padding: 0 1rem;
  }
  
  /* Style the header of the question card */
  .CQuestionCard-header {
    display: flex;
    align-items: center;
    background-color: var(--color-gray-light);
    padding: 0.5rem;
  }
  
  /* Style the profile image */
  .Chatprofile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
  }
  
  /* Style the profile info in the question card */
  .CQuestionCardprofile-info {
    display: flex;
    flex-direction: column;
  }
  
  /* Style the name in the question card */
  .CQuestionCardname {
    font-weight: bold;
    margin: 0;
  }
  
  /* Style the timestamp */
  .timestamp {
    margin: 0;
    font-size: 12px;
    color: #777;
  }
  
  /* Style the timestamp with different color */
  .ChatTimestamp2 {
    align-self: flex-start;
    font-size: 13px;
    color: #009FB0;
  }
  
 
  
  /* Style the question text */
  .CQuestion {
    text-align: left;
    font-family: 'poppins';
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  /* Style the reply section */
  .ChatReply-section {
    display: flex;
    flex-direction: column;
  }
  
  /* Style the additional answer info */
  .ChatAnsInfo {
    display: flex;
    flex-direction: column;
  }
  
  /* Style the answerer's name */
  .ChatAnsName {
    font-weight: bold;
    margin: 0;
  }
  
  /* Style the additional timestamp */
  .Anstimestamp {
    margin: 0;
    font-size: 12px;
    color: #777;
  }
  
  /* Scrollable content within the chat */
  .ScrollableContent {
    height: 60vh; /* Set the desired maximum height */
    overflow-y: auto;
  }

  .ScrollableContent::-webkit-scrollbar {
    width: 5px;
    /* margin-left: 2rem; */

}

/* Track */
.ScrollableContent::-webkit-scrollbar-track {
background: var(--color-gray-light); 
}

/* Handle */
.ScrollableContent::-webkit-scrollbar-thumb {
background:var(--color-mint);
height: 20px; 
}

/* Handle on hover */
.ScrollableContent::-webkit-scrollbar-thumb:hover {
background: #555; 
}
  
  /* Style the chat topic section */
  .SessionChatTopic {
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
  }
  
  /* Style the Q&A label */
  .ChatQA {
    align-self: flex-start;
    color: #009FB0;
    font-weight: bold;
  }
  
  /* Style the "No Questions" text */
  .NoQuestions {
    margin-left: 50px;
  }
  
  /* Style the "Anonymous messages" tag */
  .Anonymoustag {
    float: right;
  }
  
  /* Style the toggle switch container */
  .ToggleSwitch {
    display: flex;
    justify-content: flex-end;
  }
  
  /* Style the toggle button container */
  .ToggleButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .ToggleButton > p{
    margin-right: 10px;
    justify-content: flex-end;
  }

  /* Style the container for the input and button */
  .ChatReply-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #009FB0;
    border-radius: 4px;
    overflow: hidden;
    background-color: #fff;
    margin-top: 30px;
    margin-left: 30px;
    border-radius: 20px;
  }
  
  .ChatReply-textarea {
    border: none;
    outline: none;
    font-size: 14px;
    flex: 1;
  
  }
  .ChatReply-input-container button{
    background-color: transparent;
    float: right;
    margin-right: 20px;
  }
/* Add this CSS to your existing stylesheet or component */

.CheckedAnswred label.ChekedAnswerL {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.ChekedAnswerL > p {
  margin-right: 10px; 
}

/* .back_to_agenda {
  background-color:transparent;
  align-self: flex-start;
} */

.session-page-header{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  gap: 1rem;
}


.CheckedAnswerL {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-size: 14px;
  justify-content: flex-end;
  padding: 0 1rem;
  padding-bottom: 1.5rem;
}
/* .session-page-header >h3{
  margin-left: 10px;
  align-self: center;
} */
