.change-password-container{
    flex-grow: 1;
    flex-basis: 75%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 0.5rem;
    padding-right: 1rem;

    padding-top: 1rem;
    padding-bottom: 2rem;
    margin-top: 1rem;
    background-color: #F5F5F5;
}
.change-password-container h4{
    color: #009FB0;
    margin-bottom: 0.5rem;
}
.change-password-fields{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
}
.change-password-field{
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
}
.change-password-field > label {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    width: 15rem;
}
.change-password-field > input {
    display: flex;
    flex-direction: row;
    border: 1px solid var(--color-primary-variant);
    width: 100%;
    padding: 0.3rem 0.5rem;
    border-radius: 8px;
}
.change-password > input:hover {
    border: 1px solid var(--color-mint);
    
}