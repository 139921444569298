.conferencePage{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: max-content;
    min-height: 75vh;
    gap: 2rem;
}

.__conferences {
    display: flex;
    flex-direction: row;
    
}

.__conferences > hr {
    min-height: 60vh;
    width: 2px;
    background-color: var(--color-gray-light);
    margin-top: 3rem;
}

.current-conferences{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    /* border-right: 2px solid var(--color-gray-light); */
    flex-basis: 70%;
}

.current-conferences h3{
    color: var(--black, #000);
   
}

.past-conferences{
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    gap: 2rem;
}

.past-conferences h3{
    color: var(--color-primary);
}

.--past-conferences{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.past-conferences--info{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1rem 1rem;
    width: 20rem;
    cursor: pointer;
}

.past-conferences--info:hover{
    box-shadow: none;
    background-color: var(--color-green);
    color: var(--color-white);
}

.past-conferences--info img{
    width: 3rem;
}

.past-conferences--info p{
    color: #000;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.--page-footer{
    display: flex;
    flex-direction: column;
}

.--page-footer h6{
    color: var(--color-primary-variant);
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.--page-footer p{
    color: var(--color-gray-dark);
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.items {
    display: grid;
    gap: 10px;
}

