/* .notification-container {
  
} */

.create--notification {
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: var(--color-white);
  padding: 1.5rem 0;
}

.fixed-bar {
  
  width: 78%;
  padding: 1.5rem 0;
  border: 1px solid var(--color-primary-variant);
  background-color: var(--color-gray-light);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  /* margin: 1.5rem 0; */
}

.fixed-bar > input {
  width: 95%;
  background-color: var(--color-gray-light);
}

.fixed-bar > button {
  background-color: transparent;
}


.notification {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-medium);
  text-align: left;
  margin-top: 1rem;
  border-radius: 20px;
  width: 70%;
  padding: 1rem 2rem 1rem 1.5rem;
  font-size: 14px;
  float: right;
}

/* .notification-row {
  font-size: 15px;
  font-weight: normal;
  display: flex;
  align-items: center;
} */

.notification-date {
  color: #009eac;
  font-size: 14px;
  font-weight: 500;
}
.notification-time {
  font-style: italic;
  font-size: 12px;
  color: #939393;
  font-weight: 400;

}

.notification-text {
  margin-top: 0.5rem;
}
