.CommunityPage {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    position: relative;
    height: fit-content;
}

.CommunityPage__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: sticky;

    
}

.communityPage-topbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: sticky;
}

.topbar__item {
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    color: var(--color-primary-variant);
}

.topbar__item:hover{
    /* font-size: 17px; */
    color: var(--color-mint);
    font-weight: 600;
}

.active_topbar__item {
   text-decoration: underline;
   text-underline-offset: 0.5rem;
   text-decoration-thickness: 0.2rem;
   font-weight: 600;
   color: var(--color-mint);
}