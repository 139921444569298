.speakersPage {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.speakersPage__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    
}

.speakersPage__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 0 2rem 2rem 2rem;
}

.speakersPage__left {
    padding-right: 3rem;
    flex-basis: 50%;
}

.speakersPage__right {
    flex-basis: 50%;
}