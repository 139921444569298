.AddContactsection{
  background-color: #F2F2F2;
  padding: 2rem;

  width: 500px;
  height: auto;
  max-width: 185%;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

 
}

.AddContact-topic{
  color:#004E89;
  font-size: 18px;
  font-family: 'poppins';
  font-weight: bold;
  float: left;
  text-align: left;
  margin-bottom: 10px;
}


.AddContact-label{
  color: #009EAC;
  font-family: 'poppins';
  font-size: 15px;
  font-weight: 600;

  
  font-weight: bolder;
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;

}


.AddContact-inputbox input[type="text"] {
  font-size: 16px; /* Adjust the font size as needed */
  padding: 0.5rem; /* Optional: Adjust the padding as needed */
  width: 400px;
  height: 30px;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
}

.AddContact-hr-line {
  width: 100%;
  border: none;
  height: 0.2px;
  background-color: #000000; /* Adjust the color as needed */
  margin-top: 40px;
  margin-bottom: 40px;
}
.AddContact-img{
  width: 5px;
  height: 5px;
}

 
.AddContact-result{
  float: left;
  padding: 10px;
}

.AddContact-inputbox {
  display: flex;
  align-items: center;
}

.AddContact-inputbox input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.AddContact-search-button {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin-left: -1px;
  width:5px;
  height:5px;
}

.AddContact-search-button img{
  width: 20px;
  height:20px;
}
