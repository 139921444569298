.attendeesPage {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.attendeePage__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    
}

.attendeePage__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 2rem 2rem 2rem;
    column-gap: 5rem;
}

.attendeeButton_plus {
    position: fixed;
    width: 50px;
    height: 50px;
    background:#00C39B;
    cursor: pointer;
    border-radius: 100%;
    bottom: 2rem;
    right: 3rem;

    /* Mittig */
    /* top: 90%;
    left: 92%; */
}

.attendeeButton_plus:hover {
    background: var(--color-green);
}

.attendeeButton_plus:after {
    content: '';
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #ffffff;
    top: 50%;
    left: 50%;
}

.attendeeButton_plus:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    height: 50%;
    width: 4px;
}
