header{
    position: sticky;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 50;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    height: 13vh;    
}

.end-line{
    height: 1px;
    background-color: var(--color-gray-dark);
    margin: 0 3rem;
}

.header-items{
   padding: 1.5rem 3rem 0 3rem;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
}

.profile-picture{
    width: 3rem !important;
    height: 3rem !important;
}

.header-items-right{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-self: flex-end;
    align-items: center;
    background-color: transparent;
}

.user-details{
    display: flex;
    flex-direction: column;
    align-items: left;
}

.user-image-dropdown-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent;
    padding: 0 0.5rem;
}

.user-name{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 15px;
}

.user-designation{
    color: var(--color-gray-dark);
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.profile-dropdown-content {
    display: none;
    position: absolute;
    background-color: #f5f5f5;
    min-width: 10rem;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0rem;
    padding: 1rem 0;
}

.profile-dropdown-content a {
    color: black;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: block;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.profile-dropdown-content button {
    color: black;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: block;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    text-align: left;
    background-color: transparent;
}

.profile-dropdown-content a:hover,
.profile-dropdown-content button:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.profile-dropdown:hover .profile-dropdown-content {display: block;}

.header-items-right:focus + .profile-dropdown-content {
    display: block;
}

.dropdown-arrow-header {
    color: var(--color-primary);
}.profile-dropdown {
    position: relative;
    display: inline-block;
    
}