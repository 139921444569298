.__page{
    display: flex ;
    flex-direction: row;
    /* overflow: hidden; */
    /* height: 100vh;
    background-color: black; */
}

.pageRight{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* padding: 0 3rem; */
    gap: 2.5rem;
    /* overflow-x: hidden; */
}





.pageContent{
    flex-grow: 1;
    padding: 0 3rem 2rem 3rem;
    /* padding: 0 3rem 1rem 3rem; */
    /* padding-left: 1rem;
    padding-right: 1rem; */
    /* overflow-y: auto; */
    /* padding: 1.5rem 3rem; */
    /* padding: 0rem 3rem; */

    
}