.authorsPage {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.authorsPage__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    
}

.authorsPage__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 0 2rem 2rem 2rem;
    max-height: 58vh;

}

.authorsPage__left {
    padding-right: 3rem;
    flex-basis: 50%;
    overflow: auto;
}

.authorsPage__right {
    flex-basis: 50%;
    overflow: hidden;
}

.authorsPage__left::-webkit-scrollbar {
    width: 0.1rem;
  }
  
  /* Optionally, you can also style the track and thumb if needed */
  .authorsPage__left::-webkit-scrollbar-track {
    background: var(--color-gray-light);
  }
  
  .authorsPage__left::-webkit-scrollbar-thumb {
    background: var(--color-green);
  }