.agendaPage {
    display: flex;
    flex-direction: row;
    align-items: stretch;

}

.agendaPage__left {
    flex-basis: 75%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding-right: 2rem; */
}

.agendaPage__right {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: pink; */
    /* height: 100vh; */
    padding-left: 0.5rem;
    /* align-content: center; */
    /* justify-content: center; */
}

.agendaPage > hr {
    background-color: var(--color-gray-light);
    color: var(--color-gray-light);
    width: 1px;
    flex-grow: 1;
    min-height: 75vh;
}

.agenda__selection {
    display: flex;
    flex-direction: row;
    gap: 30%;
    justify-content: flex-start;
    align-items: center;
}

.agenda__selection button{
    background-color: var(--color-white);
}

.agenda__selection button h4{
    font-weight: 500;
    color: var(--color-gray-dark);
}

.agenda__content {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    gap: 1rem;
    height: 75vh;
    position: relative;

}
.agenda__content button {
    background-color: var(--color-white);
  }
  
  .agenda__content button h4 {
    font-weight: 500;
    color: var(--color-gray-dark);
  }
  
  .agenda__content > button {
    /* width: 5rem;
    height: 5rem; */
  position: absolute;
  bottom: 0;
  align-self: flex-end;
  /* margin-left: -2rem; */
  background-color: transparent;
}
  
  .__content > button > img {
    width: 5rem;
    height: 5rem;
    /* position: absolute;
      bottom: 0;
      align-self: flex-end;
      margin-left: -2rem; */
  }

/* SessionCard Styles */

.session__card {
    overflow-y: auto;
    margin-right: 2rem;
}

.session__card::-webkit-scrollbar {
    width: 5px;
    /* margin-left: 2rem; */

}

/* Track */
.session__card::-webkit-scrollbar-track {
background: var(--color-gray-light); 
}

/* Handle */
.session__card::-webkit-scrollbar-thumb {
background:var(--color-mint);
height: 20px; 
}

/* Handle on hover */
.session__card::-webkit-scrollbar-thumb:hover {
background: #555; 
}

.timeline__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.timeline__content > p {
    transform: rotate(-90deg);
    color: var(--color-gray-dark);
    font-size: 14px;
    margin-left: -3.5rem;
    width: 10rem;
}

.css-ha3bif-MuiTimelineItem-root {
    list-style: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    min-height: 100px;
}

.css-1ami7lv-MuiTimelineConnector-root {
    width: 1px;
    height: 11vh;
    background-color: var(--color-gray-light);
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.css-1ami7lv-MuiTimelineConnector-root {
    width: 2px;
    background-color: var(--color-gray-light);
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}


.css-6l1m71-MuiTimeline-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 0;
    text-align: left;
}

.css-v6mo1y-MuiTimelineDot-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: baseline;
    -ms-flex-item-align: baseline;
    align-self: baseline;
    border-style: solid;
    border-width: 2px;
    padding: 12px;
    border-radius: 50%;
    box-shadow: none;
    margin: 0 0;
    border-color: transparent;
    color: var(--color-gray-dark) !important;
    /* border-color: var(--color-gray-dark) !important; */
}

.__timeline {
    margin-left: -1rem;
    /* gap: 1rem; */
}

/* .__timline--item {
    margin-top: 1rem;
} */

.session__content__card {
    display: flex;
    flex-direction: row;
    background-color: var(--color-gray-light);
    gap: 1.5rem;
    padding: 1.5rem;
    width: 100%;
    margin-left: -2rem;
    border-radius: 8px;
    min-height: 12rem;
    /* height: 15rem; */
    
}

.session-card--header {
    flex-basis: 5%;
}

.session-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-wrap: wrap;
    overflow: hidden;
    
}

.session-details > h5{
    color: #000;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
    word-wrap: break-word;
    max-width: 30rem;
    word-wrap: break-word;
    
}

.session-info--detail {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.session-info--detail > p{
    color: #747474;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.session-info--detail > img {
    width: 0.8rem;
}

.session-card--bottom {
    display: flex;
    align-self: flex-end;
    gap: 1rem;
}



.showmore__button {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.showmore__button > button {
    color: var(--color-gray-dark);
    background-color: transparent;
}

.showmore__button > p {
    margin-top: -0.5rem;
    color: var(--color-gray-dark);
}

.__calendar {
    margin-right: -2.5rem;
}


.session__card-editable-buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    flex-basis: 5%;
    justify-content: flex-end;
    align-items: flex-start;
}

.session__card-editable-buttons > button {
    background-color: transparent;
}

/* SessionCalendar Styles */
.css-1rv8zyt-MuiStack-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    padding-top: 0px !important;
}

.css-cwhad8-MuiDateCalendar-root {
    overflow: hidden;
    width: 320px;
    max-height: 300px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.css-nk89i7-MuiPickersCalendarHeader-root {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 0 !important;
    margin-bottom: 8px;
    padding-left: 24px;
    padding-right: 12px;
    max-height: 30px;
    min-height: 30px;
}

.css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
    display: block;
    position: relative;
    overflow-x: hidden;
    min-height: 250px;
}

.event__selection > button{
    cursor: pointer;
}


.event__selection > button > h4:hover{
    color: var(--color-mint);
}

.event__selection > .buttonactive {
    background-color: var(--color-white); /* Change this to the desired active button background color */
   
    
  }

.event__selection > .buttonactive > h4 {
    color: var(--color-mint);
    
  }


