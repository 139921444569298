.allcontactPage__icons{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.allcontactpage-view{
    width: 20px;
    height: 20px;
    margin-right:5px;   
}

.allcontactpage-delete{
    width: 20px;
    height: 20px;
}