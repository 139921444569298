/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding: 0;
  }
  
  .pagination li {
    display: inline-block;
    margin: 5px;
    cursor: pointer;
    padding: 2px 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
  }
  
  .pagination li.active {
    background-color: var(--color-primary);
    color: #fff;
  }
  
  .pagination li:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
  