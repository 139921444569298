.container-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* gap: 5rem; */
    /* padding-bottom: 1rem; */
    /* height: 4vh; */
    flex-basis: 10%;

}

.logo{
    width: 10rem;
}

.home-icon{
    width: 1.5rem;
}