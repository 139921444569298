.container{
    background-color: var(--color-gray-light);
    padding-top: 4rem;
    padding-bottom: 4rem;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.middle-container {
    background-color: var(--color-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    background: linear-gradient(#fff, #fff) padding-box,
              linear-gradient(0deg, #009fb0, #f9f871) border-box;
    border-right: 7px solid transparent;
    width: 65rem;

        
}  

.forget-password-container-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-basis: 80%;
    gap: 2.5rem;
}

.forget-password-body-left{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 4rem;
    padding: 4rem 2rem 0 0;
    
}

.screen-img{
    width: 22rem;
}

.forget-password-container-body hr {
    height: 100%;
    width: 1px;
    background-color: #D9D9D9;
    color: #D9D9D9;
}

.forget-password-body-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    width: 30rem;

    padding-bottom: 1rem;
    padding-left: 1rem;
    gap: 1rem;

}

.forget-password-body-right hr {
    width: 100%;
    height: 1px;
    background-color: var(--color-primary);
}

.forget-password-body-right p {
    font-style: 'Poppins';
    font-size: 14px;
    padding-top: 1rem;
}

.forget-password-body-right > .title{
    color: var(--color-primary);
    font-family: 'Poppins';
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* * **************** FOrget-Password Form * **************** */

.foreget-password--form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.foreget-password-form{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0.5rem;

    padding-bottom: 2rem;
    width: 100%;
}

.foreget-password-form-items{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border: 2px solid var(--color-gray-medium);
    padding: 0.4rem 0 0.4rem 1rem;
    border-radius: 8px;
    width: 100%;
}

.foreget-password-form-items:hover{
   border: 2px solid var(--color-primary-variant);
   background-color: #FFF;
}

.foreget-password-form-items > img:hover{
    color: #000;
}

.foreget-password-form-items input{
    color: var(--color-primary-variant);
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #FFF;
}

.foreget-password-form-items img{
    width: 1.2rem;
}
.remember-me{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;
}

.remember-me input{
    border-radius: 5px;
    background: #D9D9D9;

}

.password-body-right-bottom button{
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: var(--color-primary);
    padding: 0.5rem 2.5rem;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.password-body-right-bottom button:hover{
    background-color: var(--color-primary-variant);
}

.password-body-right-bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    gap: 0rem;
}

.password-body-right-bottom p{
    color: var(--color-black);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.register-link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.register-link p{
    color: var(--color-black);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.register-link a{
    color: var(--color-primary-variant);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.register--link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

}

.register--link p{
    color: var(--color-black);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.register--link a{
    color: var(--color-primary-variant);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.social-icons-4{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    color: var(--color-primary);
    
}


.social-icon{
    width: 25px;
    height: 25px;
}