.add-event--form {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    border: 2px var(--color-mint) solid;
    border-radius: 15px;
    padding: 1.2rem 1.5rem;
    gap: 1.5rem;
}

.add-event--form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.add-event--form-header > button {
    background-color: transparent;
}

.event__form {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-self: center;
    /* margin-left: 2rem; */
    width: 90%;
    
}

.event__detail {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.event__detail > label {
    font-size: 14px;
    font-weight: 500;
    width: 8rem;
}

.event__detail > input {
    border: 1px solid var(--color-gray-medium);
    width: 100%;
    padding: 0.3rem 0.5rem;
    border-radius: 8px;

}

.event__detail > input:hover {
    
    border: 1px solid var(--color-mint);
    
}

.event__form > button {
    margin-top: 1rem;
    width: fit-content;
    align-self: flex-end;
    padding: 0.4rem 1.5rem;
    background-color: transparent;
    border: 2px var(--color-mint) solid;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
}

.event__form > button:hover {
    background-color: var(--color-mint);
}



  
