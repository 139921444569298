.new-user {
    background-color: #F2F2F2;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 1.5rem 2rem;
    gap: 2.5rem;
    border: 2px #F2F2F2 solid;

}

.new-user:hover {
    border: 2px var(--color-primary-variant) solid;
}

.new-user button {
    /* font: inherit; */
    cursor: pointer;
    background-color:var(--color-primary-variant);
    color: white;
    border-radius: 12px;
    width: 3rem;
    align-content: center;
    align-self: flex-end;
    padding: 0.5rem 4.5rem 0.5rem 3rem;
    text-align: center;
    
}

.new-user button:hover,
.new-user button:active {
    background-color: var(--color-mint);
}


.new-user h4 {
    color: var(--color-primary);
}

.new-user__controls {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.new-user__control {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.new-user__control > label {
    font-weight: normal;
    font-family: 'Poppins';
    font-size: 14px;
    flex-basis: 35%;
}

.new-user__control > input, select {
    
    padding: 0.2rem 1rem;
    width: 75%;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
}

.new-user__control input:hover, select:hover {
    
    border: 1px solid var(--color-mint);
}

.ocForm__bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkbox {

    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: 14px;
}

.checkbox input {
    
    padding: 12px;
    width: 1rem;
    border: 1px solid #D9D9D9;
}



/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}












