.Conference-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.Conference-popup {
  background: white;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  z-index: 1100;
}

.Conference-popup-header {
  display: flex;
  justify-content: flex-end;
}

.Conference-close-icon {
  cursor: pointer;
  font-size: 15px;
  color: var(--color-gray-dark);
}
.Conference-qr-code-container {
  margin-top: 20px;
}
