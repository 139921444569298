.attendeeCard-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border:1px solid #D9D9D9;;
    box-shadow: 2px 2px  #D9D9D9;
    border-radius: 12px;
    background-color: #FFFFFF;
    cursor: pointer;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
}

.attendeeCard-item:hover{
    border:1px solid var(--color-mint);
    box-shadow: 2px 2px var(--color-mint);
}



.attendeeCard-item__description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
}

.attendeeCard-item h2 {
    color: #3a3a3a;
    font-size: 14px;
    color: #000000;
    font-family: 'Poppins';
    font-weight: 400;

}

.attendeeCard-item-image {
    width: 50px; /* Adjust the width and height as per your requirements */
    height: 50px;
    border-radius: 50%; /* Makes the image circular */
    overflow: hidden; /* Hides any content that exceeds the circular boundary */
}

.attendeeCard-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire circular boundary */
}












