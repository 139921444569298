.NotificationPage{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}

.NotificationPage__header{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  top: 0;
  flex-basis: 2%;
}

.notification-header {
  position: sticky;
}

.notification_page_content{
  position: relative;
  /* max-height: 10vh; */

}

.notification_content {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  /* gap: 1rem; */
}

.notification_card {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-medium);
  text-align: left;
  margin-top: 1rem;
  border-radius: 20px;
  width: 100%;
  padding: 1rem 2rem 1rem 1.5rem;
}

.notification_card:hover {
  border: 1px solid var(--color-gray-medium);
  background-color: var(--color-gray-light);
  
}


.notification_card h3 {
  color: #009eac;
  font-size: 14px;
  font-weight: 500;

}
.notification_card h4 {
  /* margin-left: 4px; */
  /* margin-top: 4px; */
  font-style: italic;
  font-size: 12px;
  color: #939393;
  font-weight: 400;
}
.notification_card p {
  margin-top: 0.5rem;
  font-size: 14px;
  /* margin-left: 4px; */
  /* margin-top: 8px; */
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .notification_card {
    width: 100%;
    margin-bottom: 20px;
  }
}
.notification_button{
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 0.5rem;
}
.notification_reject_button {
  background-color: rgba(252, 167, 167, 0.17);
  border: 1px solid rgba(252, 167, 167, 0.17);
  color: rgb(254, 83, 83);
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  width: 115px;

}

.notification_reject_button:hover {
  background-color: transparent;
  border: 1px solid rgb(254, 83, 83);
  
}


.notification_accept_button {
  background-color: #9eeada3f;
  border: 1px solid #9eeada3f;
  color: #00c39b;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  width: 115px;
}

.notification_accept_button:hover {
  background-color: transparent;
  border: 1px solid #00c39b;
  
}


