.container{
    background-color: var(--color-gray-light);
    padding-top: 4rem;
    padding-bottom: 4rem;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.middle-container {
    background-color: var(--color-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    background: linear-gradient(#fff, #fff) padding-box,
              linear-gradient(0deg, #009fb0, #f9f871) border-box;
    border-right: 7px solid transparent;
    overflow: hidden;
    width: 65rem;
        
}  

.container-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    flex-basis: 80%;      
}



.body-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 4rem 2rem 0 0;
    
}

.screen-img{
    width: 22rem;
}

.body-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;    
    width: 30rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    gap: 1rem;
    
}

.container-body hr {
    height: 100%;
    width: 1px;
    background-color: #D9D9D9;
    color: #D9D9D9;
}

.title{
    color: var(--color-primary);
    font-family: 'Poppins';
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

/* * **************** Sign Up Form * **************** */

.form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
}

.form-items__fullname > .form-items {
    width: 14.4rem;
}

.form-items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    border: 2px solid var(--color-gray-medium);
    padding: 0.6rem 0.4rem 0.5rem 0.8rem;
    border-radius: 8px;
    width: 30rem;
    height: 2.5rem;
    align-items: center;
    display: flex;
    align-items: center;
    gap: 1rem;


}

.form-items__fullname {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.2rem;
    position: relative;
}

.form-items:hover{
   border: 2px solid var(--color-primary-variant);
   background-color: #FFF;
}

.form-items-cat1 {
    flex-basis: 90%;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
}

.form-items__input{
    color: var(--color-gray-dark);
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #FFF;
    width: 80%;
}

.form-items__input::placeholder {
    color: var(--color-white);
}

.form-items__label {
    position: absolute;
    left: 2.5rem;
    padding: 0 0.5rem;
    color: var(--color-gray-dark);
    cursor: text;
    font-size: 14px;
    background-color: var(--color-white);
}

.form-items img{
    width: 1.2rem;
    flex-basis: 5%;
    margin-right: 1rem;
}

.form-items__input:focus ~ .form-items__label,
.form-items:hover ~ .form-items__label,
.form-items__input:not(:placeholder-shown) ~ .form-items__label {
    top: -0.7rem;
    font-size: 12px;
    left: 2.5rem;
    color: var(--color-primary-variant);
}

.password-field-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.password-field-bottom > button {
    background-color: transparent;
    color: var(--color-gray-dark);
    align-content: center;
    justify-content: center;
    
}

.password-eye {
    width: 1rem;
    height: 1rem;
}



.remember-me{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;
}

.remember-me input{
    border-radius: 5px;
    background: #D9D9D9;
}

.remember-me input[type="checkbox"] {
    border: 2px var(--color-gray-medium) solid;
    color: var(--color-primary-variant);
    padding-bottom: 2px;
}

.remember-me p{
    color: var(--color-gray-dark);
    text-align: center;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.body-right-bottom p{
    color: var(--color-black);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.body-right-bottom button{
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: var(--color-primary);
    padding: 0.5rem 3rem;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.body-right-bottom{
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.forget_password h6{
    color: var(--color-black);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 1rem;
}

.forget_password h6:hover{
    color: var(--color-primary-variant);
}

.body-right-bottom button{
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: var(--color-primary);
    padding: 0.5rem 3rem;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.body-right-bottom button:hover {
    background-color: var(--color-primary-variant);

}

.register-link{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
}

.register-link p{
    color: var(--color-black);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.register-link a{
    color: var(--color-primary-variant);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.register-link a:hover{
    transform: scale(1.1);
}

.register--link a:hover{
    transform: scale(1.1);
}

.register--link{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
}

.register--link p{
    color: var(--color-black);
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



.social-icons-4{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    color: var(--color-primary);
    flex-basis: 5%;
}


.social-icon{
    width: 25px;
    height: 25px;
}


/* * **************** Sign Up Form - Error Messages * **************** */

.invalid-errormessage {
    position: relative;
}
  
.invalid-errormessage.active {
    color: red;
    z-index: 99999;
}
  
.invalid-errormessage.active .instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.5rem 0.75rem;
    position: absolute;
    white-space: normal;
    left: 0%; /* Center horizontally */
    transform: translateX(-80%);
    width: 12rem;
    z-index: 9999;
} 

.invalid-errormessage.active .instructions::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 82%; /* Center the tooltip arrow */
    transform: translateX(-50%);
    margin-left: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #000 transparent;
}

.invalid-errormessage.active .instructions.offscreen {
    position: absolute;
    left: -9999px;
}


.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
}

.invalid {
    position: relative;
    color: red;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}