.addAttendeePage {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.addAttendeePage__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.addAttendeePage__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 0 2rem 2rem 2rem;
}
/* .session-header h4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5rem;
  
} */

.add-attendee-label-container {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  color: rgb(0, 0, 0);
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.add-attendee-checkbox-input {
  margin-right: 5px;
  /* Add other styles here */
}

.addAttendeePage__left {
  padding-right: 3rem;
  flex-basis: 50%;
  color: var(--color-gray-dark); 
}

.addAttendeePage__right {
  flex-basis: 50%;
}
