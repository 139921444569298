.contact-card-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border:1px solid #D9D9D9;;
  box-shadow: 2px 3px  #D9D9D9;
  padding: 0.5rem;
  border-radius: 12px;
  background-color: #FFFFFF;
  width: 410px;
  height:80px;
  margin: 10px;
}

.contact-card-item:hover{
  border:1px solid #009EAC;
  box-shadow: 2px 3px  #009EAC;
}

.contact-card-item__description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  flex-flow: column-reverse;
  justify-content: flex-start;
  flex: 1;
}

.contact-card-item h2 {
  color: #3a3a3a;
  font-size: 14px;
  flex: 1;
  margin:50px;
  color: #000000;
  font-family: 'Poppins';
  font-weight: normal;
}



@media (min-width: 580px) {
  .contact-card-item__description {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
  }
}
.contact-card-item__description h2 {
  font-size: 15px;
  font-family: 'Poppins';
}

.h2 {
  font-size: 15px;
  font-family: 'Poppins';
}




.contact-card-item-image {
  width: 60px; /* Adjust the width and height as per your requirements */
  height: 60px;
  border-radius: 50%; /* Makes the image circular */
  overflow: hidden; /* Hides any content that exceeds the circular boundary */
}

.contact-card-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire circular boundary */
}


.add{
width: 30px;
height: 30px;
cursor: pointer;
margin-right: 20px;
}
