* {
    box-sizing: border-box;
  }
  
  .public_details {
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: left;
    color: #000000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: normal;
    background-color: #ffffff;
    border-radius: 10px;
    width: 70rem;
    height: 39rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .public_details h3 {
    color: #000000;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: normal;
  }
  
  .public_details_card {
    padding: 20px;
    text-align: left;
    background-color: #ffffff;
    border-radius: 10px;
    border: 3px solid #009eac;
    width: 64rem;
    height: 9rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .public_details_work h4 {
    margin: 50px auto 0;
    text-decoration: none;
  }
  