/* QACard.css */
.qacard {
    border: 1px solid #ccc;
    padding: 16px;
    margin: 16px;
    background-color: #ffffff;
    border-radius: 10px;
    height:auto;
    display:flex;
    flex-direction: column;
    padding-bottom: 25px;
    box-shadow: 2px 3px  #D9D9D9;
  }
  
  .card-header {
    display: flex;
    align-items: center;
  }
  
  .profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
  }
  
  .name {
    font-weight: bold;
    margin: 0;
  }
  
  .timestamp{
    margin: 0;
    font-size: 12px;
    color: #777;
  }

  .timestamp2{
    align-self: flex-start;
    font-size: 13px;
    color: #009FB0;
    
  }


  
  
  .reply-button {
    background-color: #009FB0;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    width:80px;
    align-self: flex-end;
  }
  
  .reply-textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 5px;
  margin-top: 20px;
  display: inline-block;
  border: 1px solid #009FB0;
  border-radius: 4px;
  box-sizing: border-box;
  height: auto;
  resize: vertical;
}
  
  
  .send-button {
    background-color: #009FB0;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 8px;
    flex-direction: column;
    align-self: flex-end;
  }
  
  .submitted-answer {
    margin-top: 12px;
    padding-top: 12px;
    padding: 16px;
    margin-left: 100px;
    border: none;
    border-radius: 4px;
    background-color: #EDECEC;
    width: auto;
    display: flex;
    flex-direction: column;

  }
  
  .answer-header {
    display: flex;
    align-items: center;
  }
  
  .answer {
    text-align: left;
    font-family: 'poppins';
    font-size: 15px;
    margin-top: 10px;
  }
  
  .question{
    text-align: left;
    font-family: 'poppins';
    font-size: 15px;
    margin-top: 20px;
  }

.reply-section{
  display: flex;
  flex-direction: column;
}

.ansInfo{
  display: flex;
  flex-direction: column;
}

.AnsName {
  font-weight: bold;
  margin: 0;
}

.Anstimestamp {
  margin: 0;
  font-size: 12px;
  color: #777;
}