.QandAPage{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.QandAPage__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    
}

