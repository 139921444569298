.community {
    font-size: 13px;
  }
  
  .public_profile_details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .public_profile_picture {
    border-radius: 50%;
    height: 10%;
    width: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 2rem;

  }
  
  .public_profile_add_icon {
    height: 20%;
    width: 20%;
    margin-left: 190px;
  }
  
  .public_profile_details-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    flex: 1;
  }
  
  .public_user-details {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
  }
  
  .public_user_name {
    font-family: "Poppins";
    font-weight: 400;
    font-size: 16px;
  }
  
  .public_user_designation {
    color: var(--color-gray-dark);
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  