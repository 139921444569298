.public_profile_cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 2rem 2rem 2rem;
    column-gap: 2rem;
    flex-direction: column;
    flex-grow: 1;
    gap: 1rem;
  }
  
  .public_profile_work_card {
    box-shadow: 2px 4px 2px 2px rgba(62, 61, 61, 0.2);
    padding: 2rem 2rem 2rem 2rem;
    text-align: left;
    background-color: #a99e9e32;
    border-radius: 10px;
    border-color: aqua;
    border-width: 5px;
    width: 28rem;
    height: 5rem;
    justify-content: center;
  }
  