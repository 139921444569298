.ocPage__icons{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.edit{
    width: 20px;
    height: 20px;
    margin-right:5px;
    
    
}



.delete{
    width: 20px;
    height: 20px;
}