.participation {
  width: 150%;
  border-collapse: collapse;
  font-family: "Poppins";
}

.participation-table-header {
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  color: var(--color-primary-variant);
  
  
}
.column-name,
.table-cell-name{
  width: 150px; 
}

.column-checkIn,
.table-cell-check-in {
  width: 100px;
  text-align: center;
}

.column-researcher,
.table-cell-researcher {
  width: 100px;
  text-align: center;
}

.column-tshirt,
.table-cell-tshirt {
  width: 100px; 
}

.column-bag,
.table-cell-bag {
  width: 100px; 
  padding-left: 1rem;
}

.column-notebook,
.table-cell-notebook {
  width: 100px; 
  padding-left: 1rem;
}

.column-confokit,
.table-cell-confokit {
  width: 100px; 
  padding-left: 1rem;
}

.column-contacts,
.table-cell-contacts {
  width: 100px; 
  padding-left: 1rem;
}

.column-questions,
.table-cell-questions {
  width: 150px; 
}
.participation td {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding-top: 1rem;
  padding-bottom: 1rem;

  
}

.participation tr {
  border-bottom: 2px var(--color-gray-light) solid;
}

.td-checkin,
.td-researcher,
.td-bag,
.td-notebook,
.td-confokit,
.td-connections {
  text-align: center;
}

.td-tsize,
.td-bag {
  text-align: center;
  padding-left: 1rem;
}

input[type="checkbox"] {
  appearance: none; /* Hide default checkbox styles */
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  background-color: white; /* Set the background color of the checkbox */
  border: 2px solid #000000; /* Customize checkbox border */
  border-radius: 4px; /* Rounded corners for checkbox */
  outline: none; /* Remove focus outline */
  cursor: pointer;
}

/* Styling for the tick mark when checkbox is checked */

input[type="checkbox"]{
  margin-right: 8px;
  border: 1px solid black;
  
  
}

input[type="checkbox"]:checked {
  background-color: white; /* Set the background color when checked */
}

input[type="checkbox"]:checked::before {
  content: "\2714"; /* Unicode for checkmark */
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px; /* Adjust as needed */
  color: #009FB0; /* Green color for the tick */
}



.participation-table {
  overflow-x: auto; /* Enable horizontal scrolling */
  
}

.table-container {
  max-height: 500px; /* Set the max height for the table body */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Add styles for fixed table headers */
.table-container thead th {
  position: sticky;
  top: 0;
  background-color: white; /* Set the background color for fixed headers */
  z-index: 1; /* Ensure the headers are above the table body */
}

/* Add styles for fixed first column (Name) */
.table-container tbody td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}

/* Add styles for the first column in the table header */
.table-container thead th:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2; /* Ensure the first column header is above the other table headers */
}


/* search box */
.search-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.3rem;
}

.search {
  font-size: 12px;
  font-weight: 500;
  width: 20rem;
  /* height: 30px; */
  padding: 0.3rem 1rem;
  border: 2px solid var(--color-gray-medium);
  font-family: 'Poppins';
  border-radius: 8px;
}

.search:hover{
  border: 2px solid var(--color-primary);
  ;
}

.search-icon {
  background-color: var(--color-primary);
  border-radius: 6px;
  width: 1.8rem;
  height: 1.8rem;
}


