.eventPage {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /* overflow-y: hidden; */
}

.eventPage__left {
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* max-height: 85vh; */
  /* padding-right: 2rem; */
}

.eventPage__right {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: pink; */
  /* height: 100vh; */
  padding-left: 2rem;
}

.eventPage > hr {
  background-color: var(--color-gray-light);
  color: var(--color-gray-light);
  width: 1px;
  min-height: 75vh;
  /* flex-grow: 1; */
}

.event__content {
  display: flex;
  flex-direction: column;
  /* padding-left: 2rem; */
  gap: 2rem;
  height: 75vh;
  position: relative;
}

.event__selection {
  display: flex;
  flex-direction: row;
  gap: 40%;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 1%;
}

.event__selection button {
  background-color: var(--color-white);
}

.event__selection button h4 {
  font-weight: 500;
  color: var(--color-gray-dark);
}

.event__content > button {
  /* width: 5rem;
    height: 5rem; */
  position: absolute;
  bottom: 0;
  align-self: flex-end;
  /* margin-left: -2rem; */
  background-color: transparent;
}

.event__content > button > img {
  width: 5rem;
  height: 5rem;
  /* position: absolute;
    bottom: 0;
    align-self: flex-end;
    margin-left: -2rem; */
}

.event_cards {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  gap: 1.5rem;
  column-gap: 2rem;
  overflow-y: auto;
  flex-basis: 98%;
  margin-right: 1rem;
  /* margin-bottom: 1rem; */
  /* max-height: 100vh; */
}

.event_cards::-webkit-scrollbar {
  width: 5px;
  /* margin-left: 2rem; */
}

/* Track */
.event_cards::-webkit-scrollbar-track {
  background: var(--color-gray-light);
}

/* Handle */
.event_cards::-webkit-scrollbar-thumb {
  background: var(--color-mint);
}

/* Handle on hover */
.event_cards::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* SessionEvents Styles */

.__event-card {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-radius: 8px;
  background: #edecec;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  margin-right: 0.5rem;
  overflow: hidden;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50rem;
}

.event-card--header {
  flex-basis: 5%;
}

.event-details {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 0.6rem;
  flex-basis: 90%;
}

.event__card-editable-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  /* flex-basis: 5%; */
}

.event__card-editable-buttons > button {
  background-color: transparent;
}

.calendarIcon {
  width: 1.5rem;
}

.event-details > h5 {
  color: #000;
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight:600;
  line-height: normal;
  /* padding-top: 0.5rem; */
  padding-bottom: 0.2rem;
  word-wrap: break-word;
  /* max-width: 15rem; */
  word-wrap: break-word;
}

.event-details > h6 {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight:500;
  line-height: normal;
  /* padding-top: 0.5rem; */
  padding-bottom: 0.2rem;
  word-wrap: break-word;
  /* max-width: 15rem; */
  word-wrap: break-word;
}

.event-info--detail {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.event-info--detail > p {
  color: #747474;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.event-info--detail > img {
  width: 0.8rem;
}

.event-info--detail .event-type{
  color: var(--color-primary-variant);
  align-items: flex-end;
  font-weight: 600;
}

.event-card--bottom {
  display: flex;
  align-self: flex-end;
  gap: 1rem;
}

.event-details > button {
  border: 1px var(--color-primary-variant) solid;
  background-color: transparent;
  color: var(--color-primary-variant);
  border-radius: 8px;
  padding: 0.2rem 0;
  margin: 0.5rem 2.5rem 0 0;
  font-weight: 500;
  width: 100%;
}

.event-details > button:hover {
  background-color: var(--color-primary-variant);
  color: var(--color-white);
}
