.participationsPage {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.participationsPage__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  /* flex-basis: 5%; */
}

/* .participation__date-selection {
  flex-basis: 10%;
} */

.participation__table_container {
  max-height: 53vh;
  overflow-y: hidden; /* Hide vertical scrolling */
  flex-basis: 80%;

  
}

.participation__page-buttons {
  position: absolute;
  bottom: 0;
  /* margin-bottom: 1rem; */
  /* flex-basis: 5%; */
}

.participation__table::-webkit-scrollbar {
  display: none;  /* margin-left: 2rem; */

}

