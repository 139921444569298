.container {
    background-color: var(--color-gray-light);
    padding-top: 4rem;
    padding-bottom: 4rem;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.home-container {
    background-color: var(--color-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 1rem 5rem;
    display: flex;
    flex-direction: column ;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 2rem;
    width: 65rem;
}


/**************** Home Page Header ****************/

.container-header-home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
}

.container-header-home img {
    width: 10rem;
}

.header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    color: black;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;   
    
}

.header-right a {
    color: black;
}

.header-right a:hover {
    color: var(--color-primary-variant);
}

.header-right button{
    background-color: white;
    border: 2px solid var(--color-primary-variant);
    border-radius: 15px;
    color: black;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500; 
    padding: 0.5rem 1.5rem;
}

.header-right button:hover{
    background-color: var(--color-primary-variant);
    color: var(--color-white);
}


/**************** Home Page Content ****************/

.container-body-home{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
}

.home__body-left{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.home__body-left p{
    color: #000;
    font-family: 'Poppins';
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    word-spacing: 1.5px;
}

.home__body-left button{
    color: #FFF;
    text-align: center;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: var(--color-primary-variant);
    border: 2px solid var(--color-primary-variant);
    padding: 0.5rem 3rem;
    width: fit-content;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.home__body-left button:hover{
    color: var(--color-primary-variant);
    background-color: var(--color-white);
    border: 2px solid var(--color-primary-variant);
}

.container-body-home img{
    width: 30rem;
}


/**************** Home Page Footer ****************/

.social-icons-3{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    color: var(--color-primary);
    
}


.social-icon{
    width: 25px;
    height: 25px;
}

.social-icon:hover{
    color: var(--color-primary-variant);
}