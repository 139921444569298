@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Poppins';
}

:root{
  --color-primary: #004E89;
  --color-primary-variant: #009FB0;
  --color-mint: #00C49D;
  --color-green: #8AE380;
  --color-yellow: #F9F871;

  --color-white: #fff;
  --color-black: #000;

  --color-gray-light: #F2F2F2;
  --color-gray-medium: #d9d9d9;
  --color-gray-dark: #747474;

}

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Poppins', sans-serif;
  background: var(--color-white);
  color: var(--color-black);

}

h2{
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}



h3{
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

h4{
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

h5{
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


h6{
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}




.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}






