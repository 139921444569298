.allcontactPage {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.allcontactPage__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    
}

.allcontactPage__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 2rem 2rem 2rem;
    column-gap: 5rem;
}



