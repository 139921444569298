.sidebar{
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    width: 15rem;
    background-color: var(--color-primary);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3rem 0 2rem 0;
    z-index: 100;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.back_to_conference {
    background-color: transparent;
    align-self: flex-start;
    margin-left: 1rem;
    margin-top: -1.5rem;
}

.nav-icon{
    height: 20px;
    width: 20px;
    color: var(--color-white);
    left: 0;
    
}

.icon-name{
    
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
    color: var(--color-white);
}

.qr{
    flex-basis: 23%;
    width: 80px;
    height: 80px;
}

.qr_s{
    flex-basis: 23%;
    width: 80px;
    height: 80px; 
    background: var(--color-primary); 
    color: var(--color-gray-light);
}

.nav {
    flex-basis: 72%;
}

.nav-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.nav-item{
    width: 15rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
    position: relative;
    left: 0;
    padding: 0.3rem 3rem 0.3rem 3rem;
}

.nav-item:hover {
    background-color: #ffffff15;
    
}

.active_navItem {
    border-right: 8px solid white;
    background-color: #ffffff15;

}

.active_navItem > span {
    font-weight: 600;
}

.middle-line{
    width: 11rem;
    height: 1px;
    margin: 1rem 1rem 1rem 3rem;
    z-index: 5;
    background-color: var(--color-gray-light, rgba(204, 204, 204, 0.87));
}

.social-icons-2{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.8rem;
    flex-basis: 5%;
}

.social-icon{
    width: 25px;
    height: 25px;
}



