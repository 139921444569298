.ContactContainer{
    position: fixed;
    display:flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  
  }
  
  .ContactPopup-section{
    background-color: #F2F2F2;
    padding: 3rem;
    margin: 3rem auto;
    width: 500px;
    height: auto;
    max-width: 185%;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 70 auto; /* To center the section horizontally, adjust margins as needed */
      
     
  }
  
  
  .ContactPopup-detail{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;
      gap: 50px;
    }
  
  .ContactPopup-detail-section-img {
      width: 30%;
      height: 30%;
      border-radius: 50%; 
      overflow: hidden;
      display: flex;
    }
  
    .ContactPopup-detail-section-img img {
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }
  
    .ContactPopup-detail-description{
      display: flex;
      flex-direction: column;
     
  
    }
    
  .ContactPopup-username{
      color:#004E89;
      font-weight: 700;
      font-size: 18px;
  }
  
  .ContactPopup-userrole{
      color:#009FB0 ;
  }
  .hr-line {
      width: 100%;
      border: none;
      height: 0.2px;
      background-color: #000000; 
      margin-top: 40px;
      margin-bottom: 40px;
    }
  .ContactCheckboxes{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .ContactPopup-social-icons{
      margin-top: 3rem;
      
  }
  
    .ContactPopup-social-icon{
     
      display: flex;
      flex-direction: row;
      gap: 5rem;
  }
  
  .ContactPopup-social-icon img{
      width: 25px;
      height: 25px;
      
  }
  
  .ContactPopup-social-icon :hover{
      background-color: #8CE280;
  }
  .connect{
      background-color: #009FB0;
      color: #ffffff;
      width: 150px;
      height: 30px;
      margin-top: 30px;
      border-radius: 10px;
      cursor: pointer;
      margin-left: 120px;
      transition-duration: 0.4s;
  }
  
  .connect:hover{
      background-color: #00C39B;
       color: #ffffff;
      
  }
  
  /* input[type="checkbox"] {
      appearance: none; 
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 20px;
      height: 20px;
      background-color: white; 
      border: 2px solid #000000; 
      border-radius: 4px; 
      outline: none; 
      cursor: pointer;
    }
  
    input[type="checkbox"]{
      margin-right: 8px;
      border: 1px solid black;
      
      
    }
  
    input[type="checkbox"]:checked {
      background-color: white; 
    }
    
    input[type="checkbox"]:checked::before {
      content: "\2713"; 
      display: inline-block;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 16px; 
      color: #00FF00; 
    } */
  
  .EmailCheckbox{
      color: #000000;
      font-family: 'poppins';
      font-weight: 300;
      display: flex;
    }
  
  .MobileCheckbox{
      color: #000000;
      font-family: 'poppins';
      font-weight: 300;
      display: flex;
  }
  