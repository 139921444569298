.UserProfile-page {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-right: 2rem;

}
.UserProfile-text {
    display: flex;
    flex-direction: row;
   justify-content: space-between;
   align-items: center;
   flex-basis: 10%;
}
.profile-text {
    gap: 2rem;
    padding-right: 2rem;

}
.edit__button button {

    background-color: var(--color-primary-variant);
    color: var(--color-white);
    padding: 0.3rem 0.5rem;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.edit__button button:hover {
    background-color: var(--color-mint);
}

.UserProfile__content{
    flex-basis: 95%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.user__bio{
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.UserProfile__content hr {
    /* height: 70vh; */
    background-color: var(--color-gray-dark);
    width:1px;
}

.user__details{
    flex-grow: 1;
    flex-basis: 75%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-left: 0.5rem;
}

.user__profile-picture{
    width: 10rem !important;
    height: 10rem !important;
    align-self: center;
}

.__bio {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.__bio label {
    font-size: 14px;
}

.__bio textarea {
    background-color: #EDECEC;
    padding: 0.5rem;
    height: 8rem;
    border: 1px solid var(--color-primary-variant);
    border-radius: 8px;
    width: 100%;
    /* justify-content: flex-start;
    align-content: flex-start;
    align-self: flex-start; */
}

.__user h6{
    color: var(--color-gray-dark);
    font-weight: 400;
    padding-top: 0.2rem;
}

.user__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.user__name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* gap: 1rem; */
}

.user__detail .name{
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    
}

.user__detail {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}

.user__detail > label {
    font-size: 14px;
    width: 6rem;
}

.user__detail > input {
    border: 1px solid var(--color-primary-variant);
    width: 18rem;
    padding: 0.3rem 0.5rem;
    border-radius: 8px;
}

.user__detail > input:hover {
    border: 1px solid var(--color-mint);
    
}

.email > input {
    border: 1px solid var(--color-primary-variant);
    width: 100%;
    padding: 0.3rem 0.5rem;
    margin-left: 0.8rem;
    border-radius: 8px;
}

.email > input:hover {
    border: 1px solid var(--color-mint);
    
}

.user__form > h4 {
    margin-top: 2rem;
    padding-bottom: 0.5rem;
}

.socialAccounts > input {
    border: 1px solid var(--color-primary-variant);
    width: 100%;
    padding: 0.3rem 0.5rem;
    border-radius: 8px;
}

.socialAccounts > input:hover {
    border: 1px solid var(--color-mint);
    
}

.socialAccounts > img {
    width: 1.5rem;
}

.user__profile__buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
    align-self: flex-end;
    padding-top: 2rem;
}

.cancel__btn{
    padding: 0.3rem 0;
    width: 7rem;
    border-radius: 8px;
    background-color: var(--color-white);
    border: 1px var(--color-primary-variant) solid;
    color: var(--color-primary-variant);
    font-weight: 600;
}

.cancel__btn:hover{
    background-color: var(--color-white);
    border: 1px var(--color-mint) solid;
    color: var(--color-mint);
    font-weight: 600;
}

.save__btn{
    padding: 0.3rem 0;
    width: 7rem;
    border-radius: 8px;
    color: var(--color-white);
    background-color: var(--color-primary-variant);

}

.save__btn:hover{
    color: var(--color-white);
    background-color: var(--color-mint);

}

.social__account__links{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.social__account__links > label {
    padding: 0.5rem 0;
    font-size: 14px;
}

.user__work {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user__work__cards {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
   

}

.user__work__card {
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #EDECEC;
    border-radius: 8px;
    padding: 1rem;

}

.user__work__card > p {
    width: 85%;
    word-wrap: break-word;
    font-size: 14px;
    
}

.user__work__card > img {
    width: 1.2rem;
}

.user__work__card > img:hover {
    transform: scale(1.1);
}

.user__work__upload {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed var(--colors-black, #000);
    background: rgba(217, 217, 217, 0.60);
    padding: 2rem;
    gap: 1rem;
}

.user__work__upload > p {
    font-size: 13px;
    text-align: center;
}

.browse__btn {
    background-color: var(--color-black);
    color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: 8px;
}

.browse__btn:hover {
    background-color: var(--color-mint);
    color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: 8px;
}
















