.delete-confirmation-popup-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  .delete-popup-section {
    background-color: #F2F2F2;
    padding: 3rem;
    margin: 3rem auto;
    max-width: 80%; /* Reduce the max-width to 80% of the viewport width */
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
    margin: 100px auto; /* Move the section slightly down from the top */
  }
  
  .delete-popup-buttons {
    display: flex;
    flex-direction: row;
    gap: 2rem; /* Reduce the gap between buttons */
    justify-content: center; /* Center the buttons horizontally */
    margin-top: 3rem; /* Add space between the buttons and the message */
  }
  
  .delete-cancel,
  .delete-continue {
    cursor: pointer;
    border: 2px solid var(--color-primary-variant);
    border-radius: 15px;
    padding: 0.5rem 2rem; /* Reduce the button width */
    font-weight: 500;
    color: var(--color-primary-variant);
    background-color: white;
  }
  
  .delete-cancel:hover,
  .delete-continue:hover,
  .delete-cancel:active,
  .delete-continue:active {
    background-color: var(--color-primary-variant);
    color: white;
  }
  